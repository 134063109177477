<template>
    <div class="account-page">

        <h1>Użytkownik</h1>
        
        <font-awesome-icon v-if="isLoading" :icon="['fas', 'spinner']" class="fa-spin mr-2 buttonFaSpin" />
        <div v-else>
            <div class="row">
                <div class="col-12 col-md-4">
                    <div class="row">
                        <div class="col-5">Aktywny:</div>
                        <div class="col-7"><b>{{ user.active ? 'tak' : 'nie' }}</b></div>
                    </div>
                    <div class="row">
                        <div class="col-5">Typ:</div>
                        <div class="col-7"><b>{{ userTypeLabel() }}</b></div>
                    </div>
                    <div class="row">
                        <div class="col-5">Imię:</div>
                        <div class="col-7"><b>{{ user.name}}</b></div>
                    </div>
                    <div class="row">
                        <div class="col-5">Nazwisko:</div>
                        <div class="col-7"><b>{{ user.surname }}</b></div>
                    </div>
                    <div class="row">
                        <div class="col-5">Email:</div>
                        <div class="col-7"><b>{{ user.email }}</b></div>
                    </div>
                    <div class="row">
                        <div class="col-5">Telefon:</div>
                        <div class="col-7"><b>{{ user.phone }}</b></div>
                    </div>
                </div>
                <div class="col-12 col-md-8">
                    <router-link :to="{name: 'AdminUserEdit',  params: { userId: user.id } }" class="mr-2"><button class="btn btn-primary" >Edytuj</button></router-link>
                </div>
            </div>

            <div  v-if="isCustomer" >
                <div class="mt-4" >
                    Stan konta: <b class="ml-2">{{ user.account_value}} zł</b>
                </div>
                    
                <h1 class="mt-5">Zamówienia aktualne</h1>
                <font-awesome-icon v-if="isLoadingOrders" :icon="['fas', 'spinner']" class="fa-spin mr-2 buttonFaSpin" />
                <div v-if="!isLoadingOrders">

                    <b-table striped hover :items="orders" :fields="fields" :tbody-tr-class="rowClass" :bordered="true" sort-icon-left >

                        <template #cell(place_order_time)="data">
                            {{ data.value | formatDateTime }}
                        </template>

                        <template #cell(type)="data">
                            {{ typeLabel( data.value ) }}
                        </template>

                        <template #cell(adress)="data">
                            {{ data.item.address.fulladdresswithcomment }}
                        </template>

                        <template #cell(start_date)="data">
                            {{ data.value | formatDate }}
                        </template>

                        <template #cell(end_date)="data">
                            {{ data.value | formatDate }}
                        </template>

                        <template #cell(status)="data" class="danger">
                            {{ statusLabel( data.value ) }}
                        </template>

                        <template #cell(options)="data">
                            <router-link :to="{name: 'AdminOrder',  params: { orderId: data.item.id } }" >
                                <button class="btn btn-secondary" >Zobacz</button>
                            </router-link>
                        </template>
                        
                    </b-table>
                </div>
                    
                <h1 class="mt-5">Koszyki</h1>
                <font-awesome-icon v-if="isLoadingBaskets" :icon="['fas', 'spinner']" class="fa-spin mr-2 buttonFaSpin" />
                <div v-if="!isLoadingBaskets">

                    <b-table striped hover :items="baskets" :fields="fieldsBaskets" :tbody-tr-class="rowClass" :bordered="true" sort-icon-left >

                        <template #cell(place_order_time)="data">
                            {{ data.value | formatDateTime }}
                        </template>

                        <template #cell(type)="data">
                            {{ typeLabel( data.value ) }}
                        </template>

                        <template #cell(adress)="data">
                            {{  data.item.address ? data.item.address.fulladdresswithcomment:null }}
                        </template>

                        <template #cell(start_date)="data">
                            {{ data.value | formatDate }}
                        </template>

                        <template #cell(end_date)="data">
                            {{ data.value | formatDate }}
                        </template>

                        <template #cell(status)="data" class="danger">
                            {{ statusLabel( data.value ) }}
                        </template>
                        
                        <template #cell(options)="data">
                            <router-link :to="{name: 'AdminOrder',  params: { orderId: data.item.id } }" >
                                <button class="btn btn-secondary" >Zobacz</button>
                            </router-link>
                        </template>
                        
                    </b-table>
                </div>
                    
                <h1 class="mt-5">Adresy</h1>
                <font-awesome-icon v-if="isLoadingAddresses" :icon="['fas', 'spinner']" class="fa-spin mr-2 buttonFaSpin" />
                <div v-if="!isLoadingAddresses">
                    <b-table striped hover :items="addresses" :fields="fieldsAddresses" :bordered="true" sort-icon-left >
                    </b-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
axios.defaults.headers.common['Accept'] = 'application/json';

import { USER_typeAdmin, USER_typeDriver, USER_typeCustomer  } from "@/constants/constants.js";

import  { SCHEDULE_typeSubscription, SCHEDULE_typeOneTimePamyent, 
    SCHEDULE_statusNew, SCHEDULE_statusToPay, SCHEDULE_statusPaid, SCHEDULE_statusEnded, SCHEDULE_statusCanceled, } from "@/constants/constants.js";

export default {
    name: 'adminUserPreview',
    data() {
        return {
            isLoading: true,
            isLoadingOrders: true,
            isLoadingBaskets: true,
            isLoadingAddresses: true,
            userId: this.$route.params.userId,
            user: null,
            orders: null,
            baskets: null,
            fields: [
                { label: 'Id', key: 'id', sortable: true },
                { label: 'Data zamówienia', key: 'place_order_time', sortable: true },
                { label: 'Rodzaj', key: 'type', sortable: true },
                { label: 'Adres', key: 'adress', sortable: true },
                { label: 'Początek dostaw', key: 'start_date', sortable: true },
                { label: 'Opłacone do', key: 'end_date', sortable: true },
                { label: 'Status', key: 'status', sortable: true },
                { label: '', key: 'options', sortable: false }
            ],
            fieldsBaskets: [
                { label: 'Id', key: 'id', sortable: true },
                { label: 'Data zamówienia', key: 'place_order_time', sortable: true },
                { label: 'Rodzaj', key: 'type', sortable: true },
                { label: 'Adres', key: 'adress', sortable: true },
                { label: 'Początek dostaw', key: 'start_date', sortable: true },
                { label: 'Opłacone do', key: 'end_date', sortable: true },
                { label: 'Status', key: 'status', sortable: true },
                { label: '', key: 'options', sortable: false }
            ],
            addresses: null,
            fieldsAddresses: [
                { label: 'Id', key: 'id', sortable: true },
                { label: 'Trasa', key: 'route_name', sortable: true },
                { label: 'Ulica', key: 'street', sortable: true },
                { label: 'Numer domu', key: 'number', sortable: true },
                { label: 'Kod pocztowy', key: 'postcode', sortable: true },
                { label: 'Miasto', key: 'city', sortable: true },
                { label: 'Komentarz', key: 'comment', sortable: true },
                { label: 'Telefon', key: 'phone', sortable: true },
                { label: 'geo lat', key: 'lat', sortable: true },
                { label: 'geo long', key: 'long', sortable: true },
                { label: 'Usunięty', key: 'deleted_at', sortable: true }
            ]
        }
    },
    mounted(){
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters['auth/token'];
        axios.get('/admin/user/get/' + this.userId ).then( (responce) => {
            this.isLoading =  false;
            this.user = responce.data;
            if ( this.isCustomer ) {
                this.loadOrders();
                this.loadBaskets();
                this.loadAddresses();
            }
        }).catch( () => {
            this.isLoading = false;
            this.$bvToast.toast(`Błąd pobierania danych`, {
                title: 'Błąd',
                autoHideDelay: 5000,
                solid: true,
                variant: 'danger',
                appendToast: true,
                toaster: 'b-toaster-top-right'
            })
        });
    },
    methods: {
        loadAddresses() {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters['auth/token'];
            axios.post('/admin/addresses', {
                "perPage": 100,
                "currentPage": 0,
                "sortBy": null,
                "asc": false,
                "filter": [],
                "userId": this.userId
            }).then(result => {
                this.isLoadingAddresses = false;
                this.addresses = result.data.addresses;
            }).catch( () => {
                this.isLoadingAddresses =  false;
                this.$bvToast.toast(`Błąd pobierania adresów`, {
                    title: 'Błąd',
                    autoHideDelay: 5000,
                    solid: true,
                    variant: 'danger',
                    appendToast: true,
                    toaster: 'b-toaster-top-right'
                })
            });
        },
        userTypeLabel() {
            let label = '';
            switch( this.user.type ) {
                case USER_typeAdmin: { label = 'Administrator'; break;}
                case USER_typeDriver: { label = 'Kierowca'; break;}
                case USER_typeCustomer: { label = 'Klient'; break;}
            }
            return label;
        },
        isCustomer() {
            return this.user.type = USER_typeCustomer;
        },
        loadOrders(){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters['auth/token'];
            axios.get('/admin/user/orders/' + this.userId).then( (responce) => {
                this.isLoadingOrders =  false;
                this.orders = responce.data;
            }).catch( () => {
                this.isLoadingOrders =  false;
                this.$bvToast.toast(`Błąd pobierania zamówień`, {
                    title: 'Błąd',
                    autoHideDelay: 5000,
                    solid: true,
                    variant: 'danger',
                    appendToast: true,
                    toaster: 'b-toaster-top-right'
                })
            });
        },
        loadBaskets(){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters['auth/token'];
            axios.get('/admin/user/baskets/' + this.userId).then( (responce) => {
                this.isLoadingBaskets =  false;
                this.baskets = responce.data;
            }).catch( () => {
                this.isLoadingBaskets =  false;
                this.$bvToast.toast(`Błąd pobierania koszyków`, {
                    title: 'Błąd',
                    autoHideDelay: 5000,
                    solid: true,
                    variant: 'danger',
                    appendToast: true,
                    toaster: 'b-toaster-top-right'
                })
            });
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.status ===  SCHEDULE_statusToPay ) return 'table-warning';
        },
        typeLabel(type) {
            let label = '';
            switch( type ){
                case SCHEDULE_typeSubscription: { label = 'Abonament miesięczny'; break; }
                case SCHEDULE_typeOneTimePamyent: { label = 'Jednorazowa dostawa'; break; }
            }
            return label;
        },
        statusLabel(status) {
            let label = '';
            switch( status ){
                case SCHEDULE_statusNew: { label = 'Koszyk'; break; }
                case SCHEDULE_statusToPay: { label = 'Nie opłacone'; break; }
                case SCHEDULE_statusPaid: { label = 'Aktywne'; break; }
                case SCHEDULE_statusEnded: { label = 'Zakończone'; break; }
                case SCHEDULE_statusCanceled: { label = 'Anulowane'; break; }
            }
            return label;
        }

    }
}
</script>
